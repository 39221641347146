body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* .wtd-statview-header-color {
  background-color: rgb(181, 124, 124) !important;
}
.wtd-statview-group-header-color {
  background-color: green !important;
  color: red !important;
}
.wtd-statview-table-highlight-column-color {
  background-color: orange !important;
  font-weight: 600 !important;
}

.wtd-statview-filter {
  background-color: purple !important;
}
.wtd-statview-table-row-color {
  background-color: red !important;
}
.wtd-statview-table-row-color:nth-of-type(odd) {
  background-color: blue !important;
}
.wtd-statview-table-row-color:hover {
  background-color: #cecece !important;
}

.wtd-statview-button-color {
  background-color: black !important;
  border: none;
}
.wtd-statview-button-color:hover {
  background-color: red !important;
}
.active {
  background-color: red !important;
} */
/* .wtd-statview-table-highlight-column-color {
  background-color: #cecece !important;
} */
