.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #252524;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: 0.3s ease;
}
.container {
  position: relative;
}
.container:hover .overlay {
  height: 100%;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}
